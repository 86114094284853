<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-status v-if="getAccess('rent.status')" :items="statusItems" @click="clickStatus($event)" :disabled="loading" />
      <a-btn-edit v-if="editRent" title="Редактирование" @click="initEditDialog++" />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <portal to="v-main">
      <edit-dialog v-if="editRent" v-model="showEditDialog" :id="id" :api="url" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
    </portal>
    <v-row v-if="!loading">
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">Фото-галерея</div>
          </template>
          <a-view-images :value="data.photos" :style="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">основная информация</div>
          </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color1="white" show-arrows center-active @change="changeTab(tab)">
              <v-tab v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab.name)" class="mr-3">{{ tab.title }} </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px">
            <v-tab-item v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab.name)" :data-name="`${tab.name} section`">
              <TabDocs v-if="'docs'.split(',').includes(tab.name)" :data="data" :category="showDocs(tab.name)" @addDocs="addNewDocs(tab.name)" :height="detailHeight" />
              <TabBalance v-if="tab.name == 'balance'" :data="{ data, id }" :height="detailHeight" />
              <s-tabs-cash v-if="tab.name == 'cashOut'" :data="data" type="out" :canAdd="true" :m="m" :height="detailHeight" />
              <s-tabs-cash v-if="tab.name == 'cashIn'" :data="data" type="in" :canAdd="true" :m="m" :height="detailHeight" />
              <TabCash v-if="tab.name == 'cashOut_del1'" :data="data" type="out" :canAdd="true" :m="m" />
              <TabCash v-if="tab.name == 'cashIn_del1'" :data="data" type="in" :canAdd="true" :m="m" />
              <comment-view v-if="tab.name == 'comments'" :id="id || 0" :targetName="'rent'" :needUpdate="needUpdateComments" :height="detailHeight" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import { statusChange, getAccess, autoHeightBlock, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, statusChange, autoHeightBlock, genModel],
  components: {
    editDialog: () => import("./../dialogs/rentDialog"),
    TabCash: () => import("./tabs/tabCash"),
    TabDocs: () => import("./tabs/tabDocs"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      needUpdateComments: 0,
      oldDoc: false,
      loading: true,
      showAddDocDialog: false,
      addDocModel: null,

      showEditDialog: false,
      confirmDialog: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.rent,
      url: "/mechti/rent",
      tab: 0,
      tab_photo: 0,
      initEditDialog: 0,
      balance: {
        debit: 0,
        credit: 0,
      },
      clientHeight: 0,
      balanceTable: {
        data: null,
        debit: 0,
        credit: 0,
      },
    };
  },
  created() {
    this.loading = true;
    this.$root.title = "Аренда";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
    if (this.$attrs?.tab) {
      console.log("set tab", this.$attrs);
      this.tab = parseInt(this.$attrs.tab);
    }
  },
  watch: {
    initEditDialog(v) {
      if (v == 1) {
        this.$nextTick(() => {
          this.showEditDialog = true;
        });
      } else {
        this.showEditDialog = true;
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  computed: {
    statuses() {
      return this.m.statuses;
    },
    statusItems() {
      let items = [];
      let res = [];
      let status = {};
      let type;
      type = "status";
      status = this.getStatus(type);
      if (status?.["next"]) {
        res = [
          ...res,
          ...this.statuses.filter((s) => {
            return status.next.includes(s.value) && s.field == type;
          }),
        ];
      }

      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data.status && el.field == "status";
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
    model: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        console.log("model", model);
        return model;
      },
    },
    modelRight() {
      return this.calcModel("viewForm1");
    },
    tabs() {
      let tabs = [
        { name: "docs", title: "Документы", show: true },
        { name: "cashOut", title: "Расход", show: true },
        { name: "cashIn", title: "Приход", show: true },
        { name: "comments", title: "Комментарии", show: true },
        { name: "acc", title: "Проводки", show: this.getAccess("rent.accounting") },
      ];
      return tabs;
    },

    editRent() {
      return this.getAccess("rent.edit");
    },
  },
  methods: {
    getStatus(type) {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data[type] && el.field == type;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
    showTab(n) {
      if (n == "acc") return false;
      return true;
    },
    showDocs(field) {
      return this.model.find((el) => el.name == field)?.category || -1;
    },
    addNewDocs(field) { 
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    changeTab(tab) {
      if (!this.idShow) this.$router.push({ query: { tab } });
    },
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = false;
    },

    updateData() {
      this.fitchData();
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
